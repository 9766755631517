import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { initializePerformance } from "firebase/performance";
import CookieConsent from "react-cookie-consent";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <>
      <App />
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        overlay
        buttonClasses="bg-indigo-500 text-white"
        contentClasses="text-center"
        onAccept={() => {
          try {
            const app = initializeApp(firebaseConfig);
            initializeAnalytics(app);
            const performance = initializePerformance(app);
            performance.instrumentationEnabled;
          } catch (err) {
            console.log(err);
          }
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  </React.StrictMode>
);

if (process.env.NODE_ENV !== "development") {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
