import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import ReadyToStart from "../Components/ReadyToStart";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Particle from "../Components/Particle";
import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";
import {
  faAngular,
  faBootstrap,
  faCss3,
  faHtml5,
  faNodeJs,
  faReact,
} from "@fortawesome/free-brands-svg-icons";

type AboutInputProps = {
  ignoreParticles?: boolean;
};

export default function MobileApps({ ignoreParticles }: AboutInputProps) {
  return (
    <>
      <BackgroundImageOverlay
        img="aboutimg.jpg"
        alt="about page background image"
        height={500}
        heading="About Us"
        sub="Get to know the team behind Devutex"
        imagePosition="center"
      />
      <div className="particles-container">
        <Particle id="particle1" ignoreParticles={ignoreParticles} />
        <Container className="conaboutboxes">
          <Row className="rowaboutboxes">
            <Col className="aboutbox" xs={10} sm={10} md={3} xl={4}>
              <div className="aboutboxicon"></div>
              <div className="aboutboxheader">Who Are We</div>
              <div className="aboutboxtext">
                We’re a small team of software developers based in the UK that
                are passionate about creating the next generation of
                applications.
              </div>
            </Col>
            <Col className="aboutbox" xs={10} sm={10} md={3} xl={4}>
              <div className="aboutboxicon"></div>
              <div className="aboutboxheader">Our Goal</div>
              <div className="aboutboxtext">
                We strive to keep up-to-date with the latest industry standard
                technology to future proof your projects without compromising
                backwards compatibility.
              </div>
            </Col>
            <Col className="aboutbox" xs={10} sm={10} md={3} xl={4}>
              <div className="aboutboxicon"></div>
              <div className="aboutboxheader">What We Do</div>
              <div className="aboutboxtext">
                <ul>
                  <li>Mobile Apps</li>
                  <li>Web Apps</li>
                  <li>Desktop Apps</li>
                  <li>Websites</li>
                  <li>WordPress</li>
                  <li>Backend Server APIs</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="squareicons">
        <div className="headerframeworks">Professional Frameworks </div>
        <Row>
          <Col className="col1" xs={4} sm={4} md={2} align="center">
            <div className="abouticons">
              <FontAwesomeIcon icon={faHtml5} />
            </div>
          </Col>
          <Col className="col1" xs={4} sm={4} md={2} align="center">
            <div className="abouticons">
              <FontAwesomeIcon icon={faCss3} />
            </div>
          </Col>
          <Col className="col1" xs={4} sm={4} md={2} align="center">
            <div className="abouticons">
              <FontAwesomeIcon icon={faNodeJs} />
            </div>
          </Col>
          <Col className="col1" xs={4} sm={4} md={2} align="center">
            <div className="abouticons">
              <FontAwesomeIcon icon={faReact} />
            </div>
          </Col>
          <Col className="col1" xs={4} sm={4} md={2} align="center">
            <div className="abouticons">
              <FontAwesomeIcon icon={faAngular} />
            </div>
          </Col>
          <Col className="col1" xs={4} sm={4} md={2} align="center">
            <div className="abouticons">
              <FontAwesomeIcon icon={faBootstrap} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="particles-container">
        <Particle id="particle2" ignoreParticles={ignoreParticles} />
        <Container className="accordioncontainer">
          <Row>
            <Col className="col1" md={6} align="center">
              <div className="bg-smallteam z-10">
                <img
                  data-src={"https://nglaxiel.sirv.com/Images/small-team0.jpg"}
                  alt="Team working together"
                  className="Sirv"
                />
              </div>
            </Col>
            <Col className="accordioncol" md={4} sm={10} xs={10} align="left">
              <div className="accordiontext">
                <div className="accordionheader">Why Choose Us?</div>
                Despite us being a small business, we have a lot to offer
              </div>
              <Accordion className="accordion1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Result Oriented Projects</Accordion.Header>
                  <Accordion.Body>
                    We work as close to your specification as possible without
                    compromising functionality, performance or security
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Customer First Attitude</Accordion.Header>
                  <Accordion.Body>
                    We will always keep you up to date with your project
                    progress and let you make small changes along the way
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Competitive Pricing</Accordion.Header>
                  <Accordion.Body>
                    We know you don’t want to spend a fortune on your big idea,
                    that’s why our rates are very competitive to what other
                    companies are offering.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Experienced Professionals</Accordion.Header>
                  <Accordion.Body>
                    Our team have been in the software development field for
                    many years and have the best skillset to carry out the job.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="numberscontainer">
          <Col lg={3} md={12} sm={12} xs={12}>
            <div className="numberstext1">
              <div className="numbersheader1">Let the numbers speak</div>
              We’ve achieved a lot so far, and we’re continuing to grow
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Container className="con1countup">
              <Row className="row1countup">
                <Col lg={2}>
                  <div className="countup1">
                    <CountUp end={14} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="numberstext2">Projects Completed</div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Container className="con1countup">
              <Row className="row1countup">
                <Col lg={2}>
                  <div className="countup1">
                    <CountUp end={14} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="numberstext2">Satisfied Clients</div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Container className="con1countup">
              <Row className="row1countup">
                <Col lg={2}>
                  <div className="countup1">
                    <CountUp end={160} suffix="K+" redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </div>
                </Col>
                <Col lg={10} xl={9} xxl={7}>
                  <div className="numberstext3">Lines of Code</div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <ReadyToStart />
    </>
  );
}
