import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

export default function PrivacyPolicy() {
  return (
    <>
      <BackgroundImageOverlay
        img={"contactimg.jpg"}
        alt="contact us page background image"
        height={500}
        heading="Privacy Policy"
        sub="How we use your data"
        imagePosition="bottom"
      />
      <div className="text-left max-w-max p-28 bg-slate-200">
        <h1>Welcome to Devutex!</h1>
        <p>
          At Devutex, accessible from www.devutex.co.uk, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Devutex and how we use it. <br />
          <br /> If you have additional questions or require more information
          about our Privacy Policy, do not hesitate to contact us. <br />
          <br /> This Privacy Policy applies only to our online activities and
          is valid for visitors to our website with regards to the information
          that they shared and/or collect in Devutex. This policy is not
          applicable to any information collected offline or via channels other
          than this website.
        </p>
        <h1>Consent</h1>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
        <h1>Information we collect</h1>
        <p>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information. <br />{" "}
          <br /> If you contact us directly, we may receive additional
          information about you such as your name, email address, phone number,
          the contents of the message and/or attachments you may send us, and
          any other information you may choose to provide.
        </p>
        <h1>How we use your information</h1>
        <p>
          We use the information we collect in various ways, including to:
          <ul className="list-disc">
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Find and prevent fraud</li>
          </ul>
        </p>
        <h1>Log Files</h1>
        <p>
          Devutex follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services’ analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users’ movement on the website, and gathering demographic information.
        </p>
        <h1>Third Party Privacy Policies</h1>
        <p>
          Devutex uses Google Analytics in order to better understand how you
          use our site. If you would like to know how Google users your data,
          you can click{" "}
          <p
            className="cursor-pointer w-auto inline text-cyan-500 hover:text-cyan-600"
            onClick={() =>
              (location.href =
                "https://policies.google.com/technologies/partner-sites")
            }
          >
            here.{" "}
          </p>
          If you would like to opt out of using Google Analytics on our website
          you can do so by installing the Google Analytics Opt Out Browser
          Plugin found{" "}
          <p
            className="cursor-pointer w-auto inline text-cyan-500 hover:text-cyan-600"
            onClick={() =>
              (location.href = "https://tools.google.com/dlpage/gaoptout")
            }
          >
            here.
          </p>
          <br />
          <br />
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers’
          respective websites.
        </p>
        <h1>CCPA Privacy Rights (Do Not Sell My Personal Information)</h1>
        <p>
          Under the CCPA, among other rights, California consumers have the
          right to:
          <ul className="list-disc">
            <li>
              Request that a business that collects a consumer’s personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </li>
            <li>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </li>
            <li>
              Request that a business that sells a consumer’s personal data, not
              sell the consumer’s personal data.
            </li>
          </ul>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <h1>GDPR Data Protection Rights</h1>
        <p>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
          <ul className="list-disc">
            <li>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </li>
            <li>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
          </ul>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us via
          email.
        </p>
        <h1>Children’s Information</h1>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
          <br />
          <br />
          Devutex does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </div>
    </>
  );
}
