import React from "react";
import { Col, Row } from "react-bootstrap";
import "./ReadyToStart.css";

function Footer() {
  return (
    <div className="readytostart">
      <Row className="row-cols-auto">
        <Col md={6} align="right">
          <div className="readytostart-text">
            <div className="readytostart-header">Ready to Start?</div>
            You’re only a few steps away from launching your dream project.
            Speak to our full-stack developers today.
          </div>
        </Col>
        <Col md={6} align="center" className="">
          <div className="readytostart-button">
            <a href="tel:02381 550 169">02381 550 169</a>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Footer;
