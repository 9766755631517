import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as BrandIcons from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="main-footer">
      <Container className="footercontainer">
        <Row className="footerrow">
          <Col className="footercol1" lg={4} md={3} sm={6}>
            <div className="devutexlogodark">
              <img
                className="Sirv"
                data-src={
                  "https://nglaxiel.sirv.com/Images/devutexlogodark.png"
                }
                alt="devutex logo"
              />
            </div>
            <Container>
              <Row className="icons-footer">
                <Col
                  lg={{ span: 1, offset: 4 }}
                  md={{ span: 1, offset: 3 }}
                  sm={{ span: 1, offset: 5 }}
                  xs={{ span: 1, offset: 5 }}
                >
                  <FontAwesomeIcon
                    className="icon"
                    icon={BrandIcons.faTwitter}
                    onClick={() => {
                      location.href = "https://twitter.com/devutex";
                    }}
                  />
                </Col>
                <Col lg={1} md={1} sm={1} xs={1}>
                  <FontAwesomeIcon
                    className="icon"
                    icon={BrandIcons.faLinkedin}
                    onClick={() => {
                      location.href =
                        "https://www.linkedin.com/company/devutex-ltd/";
                    }}
                  />
                </Col>
                <Col lg={1} md={1} sm={1} xs={1}>
                  <FontAwesomeIcon
                    className="icon"
                    icon={BrandIcons.faGithub}
                    onClick={() => {
                      location.href = "https://github.com/Devutex";
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col className="footercol2" lg={2} md={3} sm={6}>
            <h4 className="Footer-Headers">Company</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/mobile-apps">Mobile Apps</a>
              </li>
              <li>
                <a href="/websites">Websites</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </Col>
          <Col className="footercol3" lg={3} md={3} sm={6}>
            <h4 className="Footer-Headers">Contact</h4>
            <ul className="list-unstyled">
              <li>
                <a href="mailto:enquiries@devutex.co.uk">
                  enquiries@devutex.co.uk
                </a>
              </li>
              <li>
                <a href="tel:02381 550 169">02381 550 169</a>
              </li>
            </ul>
          </Col>
          <Col className="footercol4" lg={3} md={3} sm={6}>
            <h4 className="Footer-Headers">Legal</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/terms-of-service">Terms of Service</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/cookie-policy">Cookie Policy</a>
              </li>
              <li>
                <a href="/">Company Number: 13129583</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="footer-bottom">
        <p className="text-sm-center">
          &copy;{new Date().getFullYear()} Copyright © 2022 Devutex
        </p>
      </Container>
    </div>
  );
}
export default Footer;
