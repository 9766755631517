import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

export default function TermsOfService() {
  return (
    <>
      <BackgroundImageOverlay
        img={"contactimg.jpg"}
        alt="contact us page background image"
        height={500}
        heading="Terms of Service"
        sub=""
        imagePosition="bottom"
      />
      <div className="text-left max-w-max p-28 bg-slate-200">
        <h1>Welcome to Devutex!</h1>
        <p>
          These terms and conditions outline the rules and regulations for the
          use of DEVUTEX LTD’s Website, located at www.devutex.co.uk. <br />
          <br /> By accessing this website we assume you accept these terms and
          conditions. Do not continue to use Devutex if you do not agree to take
          all of the terms and conditions stated on this page. <br />
          <br /> The following terminology applies to these Terms and
          Conditions, Privacy Statement and Disclaimer Notice and all
          Agreements: “Client”, “You” and “Your” refers to you, the person log
          on this website and compliant to the Company’s terms and conditions.
          “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our
          Company. “Party”, “Parties”, or “Us”, refers to both the Client and
          ourselves. All terms refer to the offer, acceptance and consideration
          of payment necessary to undertake the process of our assistance to the
          Client in the most appropriate manner for the express purpose of
          meeting the Client’s needs in respect of provision of the Company’s
          stated services, in accordance with and subject to, prevailing law of
          Netherlands. Any use of the above terminology or other words in the
          singular, plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to same.
        </p>
        <h1>Cookies</h1>
        <p>
          We employ the use of cookies. By clicking accept on our cookie consent
          banner and your continued use of Devutex, you agree to use cookies in
          accordance with the Devutex LTD’s Privacy Policy and Cookie Policy.
        </p>
        <h1>Licence</h1>
        <p>
          Unless otherwise stated, DEVUTEX LTD and/or its licensors own the
          intellectual property rights for all material on Devutex. All
          intellectual property rights are reserved. You may access this from
          Devutex for your own personal use subjected to restrictions set in
          these terms and conditions. <br />
          <br /> You must not: <br />
          <br />
          <ul className="list-disc">
            <li>Republish material from Devutex</li>
            <li>Sell, rent or sub-license material from Devutex</li>
            <li>Reproduce, duplicate or copy material from Devutex</li>
            <li>Redistribute content from Devutex</li>
          </ul>
          This Agreement shall begin on the date hereof.
        </p>
        <h1>Hyperlinking to our Content</h1>
        <p>
          The following organizations may link to our Website without prior
          written approval:
          <ul className="list-disc">
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organizations</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site
            </li>
          </ul>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
          <br /> <br />
          We may consider and approve other link requests from the following
          types of organizations:
          <ul className="list-disc">
            <li>commonly-known consumer and/or business information sources</li>
            <li>dot.com community sites</li>
            <li>associations or other groups representing charities</li>
            <li>online directory distributors</li>
            <li>internet portals</li>
            <li>accounting, law and consulting firms</li>
            <li>educational institutions and trade associations</li>
          </ul>
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of DEVUTEX LTD; and (d) the link
          is in the context of general resource information.
          <br /> <br />
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party’s site.
          <br /> <br />
          If you are one of the organizations listed in paragraph 2 above and
          are interested in linking to our website, you must inform us by
          sending an e-mail to DEVUTEX LTD. Please include your name, your
          organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Wait 2-3 weeks for a response.
          <br /> <br />
          Approved organizations may hyperlink to our Website as follows:
          <ul className="list-disc">
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party’s site.
            </li>
          </ul>
          No use of DEVUTEX LTD’s logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </p>
        <h1>iFrames</h1>
        <p>
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>
        <h1>Content Liability</h1>
        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>
        <h1>Your Privacy</h1>
        <p>Please read our Privacy Policy.</p>
        <h1>Reservation of Rights</h1>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>
        <h1>Removal of links from our website</h1>
        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us at any moment. We will consider
          requests to remove links but we are not obligated to or to respond to
          you directly. <br />
          <br /> We do not ensure that the information on this website is
          correct, we do not warrant its completeness or accuracy; nor do we
          promise to ensure that the website remains available or that the
          material on the website is kept up to date.
        </p>
        <h1>Disclaimer</h1>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
          <ul className="list-disc">
            <li>
              limit or exclude our or your liability for death or personal
              injury
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law
            </li>
          </ul>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
          <br />
          <br />
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
      </div>
    </>
  );
}
