import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/pro-solid-svg-icons";
import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

export default function Contact() {
  return (
    <>
      <BackgroundImageOverlay
        img={"contactimg.jpg"}
        alt="contact us page background image"
        height={500}
        heading="Contact Us"
        sub="We're really looking forward to working with you. Send us an email or give us a call"
        imagePosition="bottom"
      />
      <div className="text-center py-16 bg-slate-200 w-full">
        <div className="pb-10 text-4xl font-bold">Let's talk</div>
        <div className="pb-4">
          <FontAwesomeIcon icon={Icons.faEnvelope} />
          &nbsp;&nbsp; enquiries@devutex.co.uk
        </div>
        <div className="pb-4">
          <FontAwesomeIcon icon={Icons.faPhone} />
          &nbsp;&nbsp; 02381 550 169
        </div>
      </div>
    </>
  );
}
