import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Home/Home";
import MobileApps from "./MobileApps/MobileApps";
import Websites from "./Websites/Websites";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import TermsOfService from "./Policies/TermsOfService";
import PrivacyPolicy from "./Policies/PrivacyPolicy";
import CookiePolicy from "./Policies/CookiePolicy";
import NotFound from "./Components/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="mobile-apps" element={<MobileApps />} />
        <Route path="websites" element={<Websites />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
