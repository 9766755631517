/* eslint-disable @typescript-eslint/no-var-requires */
import "./Websites.css";
import { Col, Container, Row } from "react-bootstrap";
import ReadyToStart from "../Components/ReadyToStart";
import Particle from "../Components/Particle";
import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

type WebsiteInputProps = {
  ignoreParticles?: boolean;
};

export default function Websites({ ignoreParticles }: WebsiteInputProps) {
  return (
    <>
      <BackgroundImageOverlay
        img={"websitesbg.jpg"}
        alt="test"
        height={500}
        heading="Websites"
        imagePosition="bottom"
        sub="Fast, intuitive and responsive designs to help boost your internet footprint"
      />
      <div className="particles-container">
        <Particle id="particle1" ignoreParticles={ignoreParticles} />
        <Container className="square2">
          <Row>
            <Col className="col2" md={6} align="right">
              <div className="box3-text z-10">
                <div className="box3-header">Responsive</div>
                When we build your website, we make sure it’s compatible across
                various user interfaces including computers, tablets and mobile
                phones. It is estimated that on average over 40% of users
                visiting a website will do so via mobile or tablet. No matter
                what device your customers are using to visit your site, it’s
                important to us that they all get the same, great experience.
              </div>
            </Col>
            <Col className="col1" md={6} align="center">
              <div className="websitesimgs">
                <img
                  className="Sirv"
                  data-src={"https://nglaxiel.sirv.com/Images/responsive.jpg"}
                  alt="resizing a website on a tablet"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="square1">
        <Row>
          <Col
            className="col1"
            xs={{ order: 2 }}
            sm={{ order: 2 }}
            md={{ span: 6, order: 1 }}
            align="center"
          >
            <div className="websitesimgs">
              <img
                className="Sirv"
                data-src={"https://nglaxiel.sirv.com/Images/tailormade.jpg"}
                alt="designing a custom website"
              />
            </div>
          </Col>
          <Col
            className="col2"
            xs={{ order: 1 }}
            sm={{ order: 1 }}
            md={{ span: 6, order: 2 }}
            align="left"
          >
            <div className="mbv-text">
              <div className="mvb-header">Tailor Made</div>
              We design and build your product as close to your requirements as
              possible while maintaining functionality and aesthetics. We’ll
              work closely with you to build your beautifully designed site in
              WordPress using the latest themes and templates, allowing you to
              focus on running your business.
            </div>
          </Col>
        </Row>
      </Container>
      <div className="particles-container">
        <Particle id="particle2" ignoreParticles={ignoreParticles} />
        <Container className="square2">
          <Row>
            <Col className="col2" md={6} align="right">
              <div className="box3-text z-10">
                <div className="box3-header">eCommerce</div>
                Get setup with an online shop in days built to your
                specifications using market leading tools. We’ll build you a
                WordPress site that integrates with Woocommerce so you don’t
                have to worry about handling baskets, checkout or payments.
                Manage your products, prices and photos in an easy-to-use
                portal.
              </div>
            </Col>
            <Col className="col1" md={6} align="center">
              <div className="websitesimgs">
                <img
                  className="Sirv"
                  data-src={"https://nglaxiel.sirv.com/Images/ecommerce.jpg"}
                  alt="man using credit card to buy something online"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ReadyToStart />
    </>
  );
}
