import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

export default function CookiePolicy() {
  return (
    <>
      <BackgroundImageOverlay
        img={"contactimg.jpg"}
        alt="contact us page background image"
        height={500}
        heading="Cookie Policy"
        sub=""
        imagePosition="bottom"
      />
      <div className="text-left max-w-max p-28 bg-slate-200">
        <h1>What Are Cookies</h1>
        <p>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or ‘break’
          certain elements of the sites functionality.
        </p>
        <h1>How We Use Cookies</h1>
        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <h1>Disabling Cookies</h1>
        <p>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies.
        </p>
        <h1>The Cookies We Set</h1>
        <p>
          Site preferences cookies. In order to provide you with a great
          experience on this site we provide the functionality to set your
          preferences for how this site runs when you use it. In order to
          remember your preferences we need to set cookies so that this
          information can be called whenever you interact with a page is
          affected by your preferences.
        </p>
        <h1>Third Party Cookies</h1>
        <p>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site. This site uses Google Analytics
          which is one of the most widespread and trusted analytics solution on
          the web for helping us to understand how you use the site and ways
          that we can improve your experience. These cookies may track things
          such as how long you spend on the site and the pages that you visit so
          we can continue to produce engaging content. For more information on
          Google Analytics cookies, see the official Google Analytics page. If
          you would like to opt out of using Google Analytics on our website you
          can do so by installing the Google Analytics Opt Out Browser Plugin
          found{" "}
          <p
            className="cursor-pointer w-auto inline text-cyan-500 hover:text-cyan-600"
            onClick={() =>
              (location.href = "https://tools.google.com/dlpage/gaoptout")
            }
          >
            here.
          </p>
        </p>
        <h1>More Information</h1>
        <p>
          If you would like any more information regarding how Devutex uses
          cookies on our site, you can contact us via email:
          enquiries@devutex.co.uk
        </p>
      </div>
    </>
  );
}
