import "./MobileApps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/pro-duotone-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import ReadyToStart from "../Components/ReadyToStart";
import Particle from "../Components/Particle";
import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

type MobileInputProps = {
  ignoreParticles?: boolean;
};

export default function MobileApps({ ignoreParticles }: MobileInputProps) {
  return (
    <>
      <BackgroundImageOverlay
        img={"firos-stockimg.jpg"}
        alt="test"
        height={500}
        heading="Mobile Apps"
        imagePosition="bottom"
        sub="Cross-Platform native applications built to your specification"
      />
      <div className="particles-container">
        <Particle id={"particles1"} ignoreParticles={ignoreParticles} />

        <Container className="square2">
          <Row>
            <Col
              className="col1"
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ span: 6, order: 1 }}
              align="center"
            >
              <div className="mobilesimg">
                <img
                  className="Sirv"
                  data-src={"https://nglaxiel.sirv.com/Images/mobiles.jpg"}
                  alt="mobiles-img"
                />
              </div>
            </Col>
            <Col
              className="col2"
              xs={{ order: 1 }}
              sm={{ order: 1 }}
              md={{ span: 6, order: 2 }}
              align="left"
            >
              <div className="mbv-text z-10">
                <div className="mvb-header">Versatile</div>
                Unlike standard mobile app development, our developers can
                create cross-platform apps in <b>React Native</b> that deploy to
                both Android and iOS devices but with only one code base. This
                halves both the cost and the time to launch for your project but
                without sacrificing functionality, responsiveness or design.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="square1">
        <Row>
          <Col className="col2" md={6} align="right">
            <div className="box3-text">
              <div className="box3-header">Tested</div>
              We make sure our mobile applications are rigorously tested before
              being shipped to production. This helps us minimise the number of
              bugs that unknowingly make it to the live app and would cause
              problems with the user experience.
            </div>
          </Col>
          <Col className="col1 mt-5" md={6} align="center">
            <div className="box3icon">
              <FontAwesomeIcon icon={Icons.faChartColumn} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="particles-container">
        <Particle id={"particles2"} ignoreParticles={ignoreParticles} />

        <Container className="square2">
          <Row>
            <Col
              className="col1 mt-5 z-10"
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ span: 6, order: 1 }}
              align="center"
            >
              <div className="box3icon">
                <FontAwesomeIcon icon={Icons.faGaugeHigh} />
              </div>
            </Col>
            <Col
              className="col2 z-10"
              xs={{ order: 1 }}
              sm={{ order: 1 }}
              md={{ span: 6, order: 2 }}
              align="left"
            >
              <div className="box3-text">
                <div className="box3-header">Fast Performance</div>
                By compiling to native Android and iOS code, we can keep our
                mobile applications running smoothly at 60 Frames Per Second. We
                focus heavily on performance because it’s important to us that
                you and your users get the best experience from your app.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ReadyToStart />
    </>
  );
}
