import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import * as Icons from "@fortawesome/pro-solid-svg-icons";

export default function Header() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <nav
      className={
        "absolute top-0 left-0 right-0 flex flex-wrap items-center justify-between px-2 py-3 mb-3 z-10 bg-transparent"
      }
    >
      <div className="container px-2 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start cursor-pointer">
          <div onClick={() => (window.location.href = "/")}>
            <img
              data-src={"https://nglaxiel.sirv.com/Images/logo1.png"}
              alt="devutex logo"
              className="Sirv max-h-16"
            />
          </div>

          <div
            className="text-white cursor-pointer text-xl leading-none px-3 h-10 pt-2 rounded bg-indigo-500 lg:hidden outline-none focus:outline-none inline-block height-auto"
            onClick={() => {
              setNavbarOpen(!navbarOpen);
            }}
          >
            {navbarOpen ? (
              <FontAwesomeIcon className="" icon={Icons.faTimes} />
            ) : (
              <FontAwesomeIcon className="" icon={Icons.faBars} />
            )}
          </div>
        </div>

        <div
          className={
            "lg:flex flex-grow item-center justify-content-end" +
            (navbarOpen ? " flex" : " hidden")
          }
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto bg-indigo-400 rounded pb-3 mt-1 lg:bg-transparent ">
            <HeaderItem url="/" text="Home" />
            <HeaderItem url="/mobile-apps" text="Mobile Apps" />
            <HeaderItem url="/websites" text="Websites" />
            <HeaderItem url="/about" text="About" />
            <li className="mt-2">
              <a
                href="/contact"
                className="bg-indigo-500 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

type HeaderItemProps = {
  url: string;
  text: string;
};

function HeaderItem({ url, text }: HeaderItemProps) {
  return (
    <li>
      <a
        href={url}
        className="px-3 py-2 flex items-center text-lg font-bold leading-snug text-white hover:opacity-75"
      >
        {text}
      </a>
    </li>
  );
}
