import { Container } from "react-bootstrap";
import "./BackgroundImageOverlay.css";

type bioProps = {
  img: string;
  alt: string;
  height?: number;
  heading?: string;
  sub?: string;
  imagePosition?: "top" | "center" | "bottom";
  fullscreen?: boolean;
  homePage?: boolean;
};

export default function BackgroundImageOverlay({
  img,
  alt,
  height,
  heading,
  sub,
  imagePosition,
  fullscreen,
  homePage,
}: bioProps) {
  let overlayStyleObject: React.CSSProperties;
  if (fullscreen) {
    overlayStyleObject = { height: "100vh" };
  } else {
    overlayStyleObject = { height: height + "px" };
  }

  let imageStyleObject: React.CSSProperties;
  if (fullscreen) {
    imageStyleObject = { height: "100vh", objectPosition: imagePosition };
  } else {
    imageStyleObject = {
      height: height + "px",
      objectPosition: imagePosition,
    };
  }
  return (
    <div className="overlay" style={overlayStyleObject}>
      <img
        className="Sirv image"
        alt={alt}
        style={imageStyleObject}
        data-src={"https://nglaxiel.sirv.com/Images/" + img}
        data-options="fit:cover"
        height="height"
      />

      <Container className="heading-container">
        {homePage ? (
          <>
            <div className="first-home-heading">
              Custom Software Solutions, built to your specifications
            </div>
            <div className="second-home-heading">Launch Your Project</div>
            <div className="third-home-heading">
              From websites to mobile apps, whatever your project or business
              needs, we have you covered.
            </div>
            <div
              className="transition ease-in-out delay-150 bg-indigo-500 hover:-translate-y-1 
              hover:scale-110 hover:bg-indigo-600 duration-300 rounded text-white 
              text-center inline-block h-10 align-center font-bold pt-1 px-4 text-lg cursor-pointer antialiased"
              onClick={() => {
                window.location.href = "/contact";
              }}
            >
              GET IN TOUCH
            </div>
          </>
        ) : (
          <>
            <div className="heading-main">{heading}</div>
            <div className="heading-sub">{sub}</div>
          </>
        )}
      </Container>
    </div>
  );
}
