import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/pro-duotone-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import Particle from "../Components/Particle";
import ReadyToStart from "../Components/ReadyToStart";
import BackgroundImageOverlay from "../Components/BackgroundImageOverlay";

type HomeInputProps = {
  ignoreParticles?: boolean;
};

export default function Home({ ignoreParticles }: HomeInputProps) {
  return (
    <>
      <BackgroundImageOverlay
        img={"homepagebg.jpg"}
        alt="home page background image"
        fullscreen
        homePage
        imagePosition="center"
      />
      <div className="particles-container">
        <Particle id={"particles1"} ignoreParticles={ignoreParticles} />
        <Container className="container0">
          <Row>
            <Col
              lg={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 3 }}
              sm={{ span: 6, offset: 3 }}
              xs={{ span: 8, offset: 2 }}
            >
              <div className="hhp4">What we do</div>
              <div className="php1">
                We develop high quality, versatile and reliable applications for
                all platforms. Whether it’s a cross platform mobile app, a
                detailed business website or a complicated back-end web API for
                your server, we can deliver a cost effective solution for you.
                Speak to our expert Full-Stack developers with your budget and
                we’ll tailor your needs into a successful product that will help
                you reach your goals.
              </div>
            </Col>
          </Row>
        </Container>

        <div className="container1">
          <Row className="m-3">
            <Col className="Col4x4l" md={{ span: 4, offset: 1 }}>
              <div className="box1icon">
                <FontAwesomeIcon icon={Icons.faMobileAlt} />
              </div>
              <div className="box1-header">Mobile</div>
              <div className="box1-text">
                We can build your cross-platform mobile apps and publish them to
                the app stores, leaving you with no hassle whatsoever.
              </div>
            </Col>
            <Col className="Col4x4r" md={{ span: 4, offset: 2 }}>
              <div className="box1">
                <div className="box1icon">
                  <FontAwesomeIcon icon={Icons.faDesktopAlt} />
                </div>
                <div className="box1-header">Desktop</div>
                <div className="box1-text">
                  Whether it's Windows, MacOS, or Linux, we can develop desktop
                  applications to serve any purpose.
                </div>
              </div>
            </Col>
            <Col className="Col4x4l" md={{ span: 4, offset: 1 }}>
              <div className="box1">
                <div className="box1icon">
                  <FontAwesomeIcon icon={Icons.faGlobe} />
                </div>
                <div className="box1-header">Web</div>
                <div className="box1-text">
                  We'll create a responsive, Search-Engine-Optimised website for
                  your project or business. Ask us about eCommerce!
                </div>
              </div>
            </Col>
            <Col className="Col4x4r" md={{ span: 4, offset: 2 }}>
              <div className="box1">
                <div className="box1icon">
                  <FontAwesomeIcon icon={Icons.faServer} />
                </div>
                <div className="box1-header">Full-Stack</div>
                <div className="box1-text">
                  For more advanced projects that require a back-end server, we
                  can deliver a fast, scalable and secure solution for your app.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Container className="square1">
        <Row className="m-3 z-10">
          <Col className="col2 p-2" md={6} align="right">
            <div className="box3-text">
              <div className="box3-header">Software Maintenance</div>
              We offer a variety of maintenance packages including updating to
              the latest security patches, performing software health checks and
              keeping current with the latest technology. Speak to us about your
              requirements and we’ll tailor a plan that meets your needs for an
              affordable monthly cost so you can focus on what matters in your
              business.
            </div>
          </Col>
          <Col className="col1 p-2" md={6} align="center">
            <div className="box3icon">
              <FontAwesomeIcon icon={Icons.faScrewdriverWrench} />
            </div>
          </Col>
        </Row>
      </Container>

      <div className="particles-container">
        <Particle id={"particles2"} />
        <Container className="square2">
          <Row className="m-3">
            <Col
              className="col1 z-10"
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ span: 6, order: 1 }}
              align="center"
            >
              <div className="box3icon">
                <FontAwesomeIcon icon={Icons.faHandHoldingHeart} />
              </div>
              <div className="freecare">Up to 90 days Free Care</div>
            </Col>
            <Col
              className="col2 z-10"
              xs={{ order: 1 }}
              sm={{ order: 1 }}
              md={{ span: 6, order: 2 }}
              align="left"
            >
              <div className="box3-text">
                <div className="box3-header">After Launch Care</div>
                Our customer focused business ethic means we want to deliver the
                best possible product to you. During the development process we
                will keep you up to date with our progress and allow you to have
                your say if you want any changes made. If you want to make small
                changes to your software after we’ve completed it, then we’ll
                honour this for free for up to 90 days (depending on your
                project size) after your product has launched. We are always
                committed to making sure you are 100% happy with the software we
                provide.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ReadyToStart />
    </>
  );
}
