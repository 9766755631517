import BackgroundImageOverlay from "./BackgroundImageOverlay";

export default function NotFound() {
  return (
    <>
      <BackgroundImageOverlay
        img={"contactimg.jpg"}
        alt="contact us page background image"
        height={500}
        heading="404 Page Not Found"
        sub="sorry about that, try another page instead"
        imagePosition="bottom"
      />
    </>
  );
}
